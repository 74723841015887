.navbar {
  position: fixed;
  width: 100%;
  font-size: 1.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  z-index: 6;
  transition: all 0.4s ease-out; }

.scrolled {
  background: white; }

.hide-background {
  background: transparent; }

.navbar .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }

.navbar .logo {
  font-size: 2rem;
  font-weight: 600;
  opacity: 100;
  transition: all 0.4s ease-out; }
  .navbar .logo.hide {
    opacity: 0; }

.navbar .nav {
  justify-self: flex-end;
  align-items: center;
  justify-content: center; }

.navbar .nav a {
  padding: 0 1rem;
  display: inline-block;
  position: relative; }

.nav .nav-item a:after {
  content: "";
  position: absolute;
  top: calc(50% + 15.2px);
  left: calc(50% - 48px);
  transition: all 0.4s ease-out;
  width: 100px;
  height: 3px;
  background: #9fedd7;
  transform: scale3d(0, 1, 1) rotate(0deg);
  transform-origin: left bottom;
  transform-style: preserve-3d; }

.nav .nav-item:hover a:after {
  transform: scale3d(1, 1, 1) rotate(-16deg); }

.nav .nav-item .active:after {
  transform: scale3d(1, 1, 1) rotate(-16deg); }

.navbar .nav li {
  flex-shrink: 0; }

.navbar .menu-btn-container {
  justify-self: flex-end;
  align-items: center;
  justify-content: center; }

.menu-btn {
  z-index: 3;
  cursor: pointer;
  transition: all 0.4s ease-out; }
  .menu-btn .btn-line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: black;
    transition: all 0.4s ease-out; }
  .menu-btn.close {
    transform: rotate(180deg); }
    .menu-btn.close .btn-line:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px); }
    .menu-btn.close .btn-line:nth-child(2) {
      opacity: 0; }
    .menu-btn.close .btn-line:nth-child(3) {
      transform: rotate(135deg) translate(-6px, 6px); }

.mob-menu {
  position: fixed;
  top: 0;
  width: 100%;
  opacity: 0.9;
  visibility: hidden;
  z-index: 5; }
  .mob-menu.show {
    visibility: visible; }
  .mob-menu-nav {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    float: left;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background: #f7f6f4;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s ease-out; }
    .mob-menu-nav.show {
      transform: translate3d(0, 0, 0); }
  .mob-menu-branding {
    width: 100%;
    height: 20vh;
    background: #8ae9ce;
    transition: all 0.4s ease-out;
    transform: translate3d(-100%, 0, 0);
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center; }
    .mob-menu-branding.show {
      transform: translate3d(0, 0, 0); }
    .mob-menu-branding .logo {
      font-size: 2.5rem;
      font-weight: 600; }
  .mob-menu-nav-item {
    transform: translate3d(600px, 0px, 0);
    transition: all 0.4s ease-out; }
    .mob-menu-nav-item.show {
      transform: translate3d(0, 0, 0); }
    .mob-menu-nav-item a {
      display: inline-block;
      position: relative;
      font-size: 1.7rem;
      padding: 1rem 0; }
    .mob-menu-nav-item a:after {
      content: "";
      position: absolute;
      top: calc(50% + 15.2px);
      left: calc(50% - 48px);
      transition: all 0.4s ease-out;
      width: 100px;
      height: 3px;
      background: #9fedd7;
      transform: scale3d(0, 1, 1) rotate(0deg);
      transform-origin: left bottom;
      transform-style: preserve-3d; }
    .mob-menu-nav-item .active:after {
      transform: scale3d(1, 1, 1) rotate(-16deg); }
  .mob-menu-nav-item:hover a:after {
    transform: scale3d(1, 1, 1) rotate(-16deg); }

.mob-menu-nav-item:nth-child(1) {
  transition-delay: 0.1s; }

.mob-menu-nav-item:nth-child(2) {
  transition-delay: 0.2s; }

.mob-menu-nav-item:nth-child(3) {
  transition-delay: 0.3s; }

.mob-menu-nav-item:nth-child(4) {
  transition-delay: 0.4s; }

.mob-menu-nav-item:nth-child(5) {
  transition-delay: 0.5s; }

.loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 50; }

.pinus-container {
  position: absolute;
  z-index: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  column-gap: 0px; }

.pinus1,
.pinus2 {
  z-index: 3;
  cursor: pointer;
  transition: all 0.4s ease-out;
  display: grid; }
  .pinus1 .pinus-line,
  .pinus2 .pinus-line {
    height: 6px;
    margin: 7px 0;
    background: #9fedd7; }
  .pinus1 .pinus-line:nth-child(1),
  .pinus2 .pinus-line:nth-child(1) {
    width: 40px; }
  .pinus1 .pinus-line:nth-child(2),
  .pinus2 .pinus-line:nth-child(2) {
    width: 50px; }
  .pinus1 .pinus-line:nth-child(3),
  .pinus2 .pinus-line:nth-child(3) {
    width: 60px; }

.pinus1 {
  justify-items: right; }
  .pinus1 .pinus-line {
    transform-origin: right;
    -moz-transform-origin: right;
    -webkit-transform-origin: right;
    -ms-transform-origin: right; }

.pinus2 {
  justify-items: left; }
  .pinus2 .pinus-line {
    transform-origin: left;
    -moz-transform-origin: left;
    -webkit-transform-origin: left;
    -ms-transform-origin: left; }

@keyframes right-myAnimation1 {
  0% {
    transform: rotate(45deg) translate(0px, 0px); }
  0% {
    transform: rotate(45deg) translate(0px, 0px); }
  20% {
    transform: rotate(45deg) translate(10px, 0px); }
  40% {
    transform: rotate(45deg) translate(0px, 0px); }
  100% {
    transform: rotate(45deg) translate(0px, 0px); } }

.pinus2 .pinus-line:nth-child(1) {
  -webkit-animation: right-myAnimation1 1.2s infinite;
  /* Safari 4.0 - 8.0 */
  animation: right-myAnimation1 1.2s infinite ease-in-out; }

@keyframes right-myAnimation2 {
  0% {
    transform: rotate(45deg) translate(0px, 0px); }
  20% {
    transform: rotate(45deg) translate(0px, 0px); }
  40% {
    transform: rotate(45deg) translate(10px, 0px); }
  60% {
    transform: rotate(45deg) translate(0px, 0px); }
  100% {
    transform: rotate(45deg) translate(0px, 0px); } }

.pinus2 .pinus-line:nth-child(2) {
  -webkit-animation: right-myAnimation2 1.2s infinite;
  /* Safari 4.0 - 8.0 */
  animation: right-myAnimation2 1.2s infinite ease-in-out; }

@keyframes right-myAnimation3 {
  0% {
    transform: rotate(45deg) translate(0px, 0px); }
  40% {
    transform: rotate(45deg) translate(0px, 0px); }
  60% {
    transform: rotate(45deg) translate(10px, 0px); }
  80% {
    transform: rotate(45deg) translate(0px, 0px); }
  100% {
    transform: rotate(45deg) translate(0px, 0px); } }

.pinus2 .pinus-line:nth-child(3) {
  -webkit-animation: right-myAnimation3 1.2s infinite;
  /* Safari 4.0 - 8.0 */
  animation: right-myAnimation3 1.2s infinite ease-in-out; }

@keyframes left-myAnimation1 {
  0% {
    transform: rotate(-45deg) translate(0px, 0px); }
  0% {
    transform: rotate(-45deg) translate(0px, 0px); }
  20% {
    transform: rotate(-45deg) translate(-10px, 0px); }
  40% {
    transform: rotate(-45deg) translate(0px, 0px); }
  100% {
    transform: rotate(-45deg) translate(0px, 0px); } }

.pinus1 .pinus-line:nth-child(1) {
  -webkit-animation: left-myAnimation1 1.2s infinite;
  /* Safari 4.0 - 8.0 */
  animation: left-myAnimation1 1.2s infinite ease-in-out; }

@keyframes left-myAnimation2 {
  0% {
    transform: rotate(-45deg) translate(0px, 0px); }
  20% {
    transform: rotate(-45deg) translate(0px, 0px); }
  40% {
    transform: rotate(-45deg) translate(-10px, 0px); }
  60% {
    transform: rotate(-45deg) translate(0px, 0px); }
  100% {
    transform: rotate(-45deg) translate(0px, 0px); } }

.pinus1 .pinus-line:nth-child(2) {
  -webkit-animation: left-myAnimation2 1.2s infinite;
  /* Safari 4.0 - 8.0 */
  animation: left-myAnimation2 1.2s infinite ease-in-out; }

@keyframes left-myAnimation3 {
  0% {
    transform: rotate(-45deg) translate(0px, 0px); }
  40% {
    transform: rotate(-45deg) translate(0px, 0px); }
  60% {
    transform: rotate(-45deg) translate(-10px, 0px); }
  80% {
    transform: rotate(-45deg) translate(0px, 0px); }
  100% {
    transform: rotate(-45deg) translate(0px, 0px); } }

.pinus1 .pinus-line:nth-child(3) {
  -webkit-animation: left-myAnimation3 1.2s infinite;
  /* Safari 4.0 - 8.0 */
  animation: left-myAnimation3 1.2s infinite ease-in-out; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  font-family: Prompt, Arial, Helvetica, sans-serif;
  line-height: 1.6;
  color: black;
  font-size: 1rem;
  height: 100%;
  margin: 0;
  scroll-behavior: smooth; }

body p,
body h4 {
  font-family: Raleway, sans-serif; }

h1,
h2,
h3,
h4 {
  line-height: 1.3; }
  h1.lg-heading,
  h2.lg-heading,
  h3.lg-heading,
  h4.lg-heading {
    font-size: 6rem;
    line-height: 1;
    color: white; }
    h1.lg-heading .secondary-txt,
    h2.lg-heading .secondary-txt,
    h3.lg-heading .secondary-txt,
    h4.lg-heading .secondary-txt {
      color: #9fedd7; }
  h1.sm-heading,
  h2.sm-heading,
  h3.sm-heading,
  h4.sm-heading {
    color: white;
    padding: 0.2rem 1rem;
    background: rgba(73, 221, 179, 0.5);
    font-weight: 600; }

.pocetna {
  overflow: hidden; }

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0rem 2rem; }

.content-box {
  position: relative; }

nav a {
  color: black;
  text-decoration: none; }

ul {
  list-style: none; }

img {
  width: 100%; }

.pocetna-b,
.pocetna-c {
  padding: 5rem 0; }

.pocetna-a {
  position: relative;
  width: 100%;
  height: 90vh;
  background: url(./components/imgs/pinus-point-pocetna.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center bottom;
  padding: 5rem 0 0 0; }
  .pocetna-a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin: 0;
    background: black;
    opacity: 0.1; }

.pocetna-a .container {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
  justify-content: center;
  height: 100%; }
  .pocetna-a .container .lg-heading {
    display: inline-block; }
  .pocetna-a .container .sm-heading {
    display: block;
    text-align: left; }
  .pocetna-a .container div:nth-child(2) {
    z-index: 3; }

.decor-circle {
  border: 2px solid #9fedd7;
  border-radius: 50%; }

.pocetna-a .decor-circle {
  position: absolute;
  left: 32%;
  top: 0%;
  width: 30rem;
  height: 30rem;
  transition: all 0.4s ease-out; }

.pocetna-b {
  margin: 2rem 0; }

.pocetna-b .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem;
  justify-content: center;
  overflow: visible; }

.pocetna h2 {
  font-size: 2.8rem;
  color: #333;
  font-weight: 600; }

.pocetna-b p {
  margin: 1rem 0;
  font-weight: 400;
  font-size: 1rem; }

.pocetna-b img {
  z-index: 2; }

.pocetna-b .img-square {
  width: 100%;
  margin-top: 0px; }

.pocetna-b .img-squareW {
  width: 100%;
  margin-left: 10%;
  margin-top: 0px; }

.pocetna-b .img-squareH {
  width: 100%;
  margin-left: -20%; }

.pocetna-b .img-square:nth-child(2) {
  margin-left: 45%;
  margin-top: -40%; }

.btn {
  font-size: 1.1rem;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  display: inline-block;
  padding: 0.8rem 0rem;
  transition: all 0.4s ease-out;
  position: relative; }
  .btn:after {
    content: "";
    position: absolute;
    transition: all 0.4s ease-out;
    top: 50%;
    margin-top: -3px;
    right: -15px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #9fedd7; }
  .btn:hover:after {
    height: 3px;
    border-radius: 1px;
    margin-top: 0px;
    width: 150%; }
  .btn:focus {
    outline: none; }

.godine {
  position: relative;
  float: right;
  margin-top: -15%;
  z-index: 3;
  right: 0%; }
  .godine h1 {
    font-size: 6.5rem;
    line-height: 0.8;
    color: #9fedd7; }
  .godine p {
    margin-top: 0;
    font-family: Prompt;
    font-weight: 400;
    font-size: 1rem; }
  .godine .krug {
    width: 14rem;
    height: 14rem;
    position: absolute;
    border-radius: 50%;
    display: block;
    border: 3px solid #9fedd7;
    top: -75%;
    right: -40%; }

.pocetna-c {
  padding: 1rem 0 0 0; }

.pocetna-c .izdvojeno {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 1rem;
  gap: 4px;
  margin: auto;
  max-width: 1600px; }
  .pocetna-c .izdvojeno a:nth-child(1) {
    grid-row: 1/2;
    grid-column: 2/3; }
  .pocetna-c .izdvojeno a:nth-child(2) {
    grid-row: 2/3;
    grid-column: 2/3; }
  .pocetna-c .izdvojeno a:nth-child(3) {
    grid-row: 1/3;
    grid-column: 1/2; }
  .pocetna-c .izdvojeno a:nth-child(4) {
    grid-row: 1/2;
    grid-column: 3/4; }
  .pocetna-c .izdvojeno a:nth-child(5) {
    grid-row: 1/2;
    grid-column: 4/5; }
  .pocetna-c .izdvojeno a:nth-child(6) {
    grid-row: 2/3;
    grid-column: 3/5; }
  .pocetna-c .izdvojeno img,
  .pocetna-c .izdvojeno a {
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .pocetna-c .izdvojeno img {
    max-width: 100%;
    transition: all 0.2s ease-out; }
  .pocetna-c .izdvojeno img:hover {
    -webkit-transform: scale(1.02);
    transform: scale(1.02); }
  .pocetna-c .izdvojeno a:hover {
    cursor: pointer; }

.fslightbox-toolbar {
  background: rgba(255, 255, 255, 0) !important; }

.fslightbox-toolbar-button,
.fslightbox-slide-btn {
  transition: all 0.4s ease-out;
  background: rgba(159, 237, 215, 0.2) !important; }

.fslightbox-toolbar-button:hover,
.fslightbox-slide-btn:hover {
  background: #9cecd6 !important; }

.fslightbox-svg-path {
  fill: #333 !important; }

.fslightbox-container {
  background: rgba(255, 255, 255, 0.8) !important; }

/*Footer*/
.section-footer {
  position: relative;
  background: #333;
  color: #ccc;
  padding: 3rem 0 2rem 0;
  line-height: 1.9;
  z-index: 4; }

.section-footer .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem; }

.section-footer .container h2 {
  font-size: 2rem;
  text-align: center; }

.section-footer .container p {
  font-family: Prompt, Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 400; }

.section-footer .footer-opis {
  text-align: center; }

.section-footer .container .copyright {
  text-align: right; }

/* PARALAX THINGS*/
.parallax-footer-trava {
  width: 30%;
  position: absolute;
  margin-left: 60%;
  z-index: 5;
  margin-top: 550px; }

.footer-trava {
  width: 100%;
  opacity: 0.6; }

.parallax-header-trava {
  width: 30%;
  position: absolute;
  margin-left: 5%;
  margin-top: 550px;
  z-index: 4; }

.header-trava {
  width: 100%;
  opacity: 0.9; }

.header-trava .trava_svg__cls-4,
.header-trava .trava_svg__cls-3 {
  stroke: #edeae5; }

.decor-circle-parallax {
  border: 3px solid #9fedd7;
  border-radius: 50%;
  width: 20rem;
  height: 20rem; }

.decor-circle-parallax-banner {
  position: absolute;
  width: auto; }

.pocetna .decor-circle-parallax-banner {
  position: absolute;
  margin-left: 10%;
  margin-top: 10%;
  width: auto; }

.about .decor-circle-parallax-banner {
  position: absolute;
  margin-left: -10%;
  margin-top: -10%;
  width: auto;
  z-index: 3; }

.usluge .parallax-trava {
  width: 30%;
  position: absolute;
  margin-left: -10%;
  margin-top: 200px;
  z-index: 4; }

.usluge .trava {
  width: 100%;
  opacity: 0.8; }

.usluge .trava .trava_svg__cls-4,
.usluge .trava .trava_svg__cls-3 {
  stroke: #9fedd7; }

.cross1,
.cross2 {
  width: 100%; }

.parallax-cross1,
.parallax-cross2 {
  width: 30px;
  position: absolute;
  z-index: 4; }

.parallax-cross1 {
  margin-left: 35%;
  margin-top: -20%; }

.parallax-cross2 {
  margin-left: 25%;
  margin-top: -40%; }

.usluge-a .container .usluge-opis {
  float: left;
  width: 50%;
  padding: 4rem 0;
  position: relative;
  z-index: 4; }

.usluge-a {
  overflow: hidden;
  position: relative;
  z-index: 3;
  top: 7rem;
  background: url(./components/imgs/usluge/BW_usluge.jpg);
  background-size: 65% auto;
  background-clip: border-box;
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-repeat: no-repeat;
  background-position: right center; }
  .usluge-a:after {
    content: "";
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7); }

.usluge-b {
  margin-top: 6rem;
  padding: 5rem 0; }

.usluge-b .container {
  position: relative;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  row-gap: 10rem;
  z-index: 4; }
  .usluge-b .container .usluga-box:nth-child(3n - 1) {
    top: 8rem;
    position: relative; }
  .usluge-b .container .usluga-box:nth-child(3n) {
    top: 16rem;
    position: relative; }
  .usluge-b .container .usluga-box {
    width: 100%;
    height: 100%; }
    .usluge-b .container .usluga-box img {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
      transition: all 0.4s ease-out; }
    .usluge-b .container .usluga-box:hover img {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      filter: grayscale(0%); }

.usluge h3 {
  color: #333;
  font-weight: 600;
  padding: 0.8rem 0rem;
  border-bottom: 3px solid #9fedd7; }
  .usluge h3 span {
    color: #9fedd7;
    font-size: 0.9rem; }

.usluge h4 {
  padding: 1rem 0;
  font-weight: 400;
  font-size: 1rem; }

.usluge h2,
.about h2,
.reference h2,
.kontakt h2 {
  font-size: 2.8rem;
  color: #333;
  font-weight: 600; }

.usluge p,
.about p,
.reference p,
.kontakt p {
  padding: 1rem 0;
  font-weight: 400;
  font-size: 0.9rem; }

.about,
.kontakt {
  padding: 3rem 0 0 0; }

.about-a {
  position: relative; }

.about-a .container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  justify-items: center;
  grid-gap: 2rem; }
  .about-a .container img {
    position: relative;
    z-index: 3; }
  .about-a .container .about-slika {
    display: grid;
    grid-template-columns: 1fr;
    align-content: center; }

.about-opis {
  position: relative;
  padding: 4rem 0 4rem 1rem;
  z-index: 4; }

.about-b,
.reference-b {
  position: relative;
  background-color: #edeae5;
  margin-top: 4rem; }

.blok {
  padding: 4rem 0;
  width: 60%; }

.reference {
  padding: 3rem 0 0 0;
  /* --------------------------------------------GALERIJA---------------------------*/
  /*----------------------------------------------------------------------------------------*/ }
  .reference .reference-opis {
    padding: 4rem 0 0 0; }
  .reference p {
    font-size: 0.9rem; }
  .reference h3 {
    font-weight: 600;
    color: #333; }
  .reference h4 {
    font-weight: 400;
    color: #333;
    text-align: center;
    font-family: Prompt, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    width: 100%; }
  .reference .masonry-with-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 4rem 0 2rem 0; }
    .reference .masonry-with-columns img {
      height: 200px;
      width: auto;
      line-height: 200px;
      margin: 0 4px 4px 0;
      flex: 0.5 0 auto;
      cursor: pointer; }

.kontakt-a {
  margin-top: 1rem; }
  .kontakt-a .container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: flex-end;
    align-content: center;
    grid-gap: 2rem; }

.kontakt-opis {
  position: relative;
  padding: 4rem 0 0rem 0rem;
  z-index: 4; }

.kontakt .decor-circle-parallax {
  position: absolute;
  margin-right: 60%;
  margin-top: 5%; }

.kontakt-form {
  position: relative;
  padding: 4rem 2rem 0rem 3rem;
  width: 100%;
  font-family: Prompt, Arial, Helvetica, sans-serif !important; }
  .kontakt-form button {
    background-color: transparent;
    border: none;
    font-size: 1.1rem;
    text-decoration: none;
    color: #333;
    font-weight: 600;
    display: inline-block;
    padding: 0;
    margin-bottom: 1rem;
    cursor: pointer;
    font-family: Prompt, Arial, Helvetica, sans-serif; }
  .kontakt-form input,
  .kontakt-form textarea {
    position: relative;
    width: 100%;
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
    font-family: Prompt, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    background-color: #edeae5;
    border: 0;
    border-bottom: 0px solid #9fedd7;
    transition: all 0.2s ease-out; }
    .kontakt-form input:focus,
    .kontakt-form textarea:focus {
      border: 0px;
      outline: none; }
  .kontakt-form textarea {
    resize: none;
    vertical-align: top; }
  .kontakt-form span {
    display: block;
    margin-bottom: 1rem;
    position: relative;
    width: 100%; }
    .kontakt-form span:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      height: 3px;
      width: 0;
      background-color: #9fedd7;
      transition: all 0.2s ease-out; }
  .kontakt-form .focused:after {
    width: 100%; }

.kontakt .kontakt-b {
  margin-top: 4rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center; }

.kontakt .info-box {
  position: relative;
  padding: 4rem 0;
  background-color: #edeae5; }
  .kontakt .info-box div {
    position: relative;
    width: 100%;
    float: right;
    padding-left: 4rem; }

.kontakt h3 {
  color: #333;
  font-weight: 600;
  padding: 0.5rem 0rem; }

.kontakt h4 {
  padding: 0.15rem 0;
  font-weight: 400;
  font-size: 1rem; }

.marker {
  position: absolute;
  width: 10%;
  height: 10%;
  z-index: 10; }

@media screen and (min-width: 1171px) {
  .navbar .menu-btn-container {
    display: none; }
  .navbar .nav {
    display: flex; } }

@media screen and (min-width: 869px) and (max-width: 1170px) {
  .navbar .menu-btn-container {
    display: none; }
  .navbar .nav {
    display: flex; } }

@media screen and (max-width: 868px) {
  .navbar .nav {
    display: none; }
  .navbar .menu-btn-container {
    display: flex; }
  .pocetna-a .decor-circle {
    left: 20%;
    top: 6%;
    width: 25rem;
    height: 25rem; }
  h1.lg-heading {
    font-size: 4.2rem; }
  h3.sm-heading {
    font-size: 1rem; }
  .pocetna h2,
  .usluge h2,
  .reference h2,
  .about h2,
  .kontakt h2 {
    font-size: 2.2rem; }
  .pocetna-b .container {
    grid-template-columns: 1fr;
    padding: 0;
    justify-items: center; }
    .pocetna-b .container .content-box {
      background: white;
      width: 90%;
      z-index: 3;
      padding: 1rem 1rem 1rem 1rem; }
    .pocetna-b .container .slikaT {
      display: none; }
    .pocetna-b .container .slikaR {
      margin-top: -20%; }
    .pocetna-b .container .slikaB {
      margin-top: -0%; }
    .pocetna-b .container .img-squareH {
      margin-top: -30%;
      width: 100%;
      margin-left: 0; }
    .pocetna-b .container .img-squareW {
      display: none; }
    .pocetna-b .container .kvaliteta {
      grid-row: 3;
      background: white;
      width: 90%;
      z-index: 3;
      padding: 1rem 1rem 1rem 1rem; }
    .pocetna-b .container .kvaliteta-slika {
      grid-row: 4; }
    .pocetna-b .container .godine {
      margin-top: -80%;
      float: left;
      margin-left: 10%; }
      .pocetna-b .container .godine p {
        font-size: 0.8rem; }
      .pocetna-b .container .godine h1 {
        font-size: 4.2rem; }
      .pocetna-b .container .godine .krug {
        width: 11rem;
        height: 11rem; }
  .pocetna-c .izdvojeno {
    grid-template-columns: repeat(2, 1fr); }
    .pocetna-c .izdvojeno a:nth-child(1) {
      grid-row: 1/2;
      grid-column: 1/2; }
    .pocetna-c .izdvojeno a:nth-child(2) {
      grid-row: 4/5;
      grid-column: 1/2; }
    .pocetna-c .izdvojeno a:nth-child(3) {
      grid-row: 3/5;
      grid-column: 2/3; }
    .pocetna-c .izdvojeno a:nth-child(4) {
      grid-row: 3/4;
      grid-column: 1/2; }
    .pocetna-c .izdvojeno a:nth-child(5) {
      grid-row: 1/2;
      grid-column: 2/3; }
    .pocetna-c .izdvojeno a:nth-child(6) {
      grid-row: 2/3;
      grid-column: 1/3; }
  .section-footer .container {
    grid-template-columns: 1fr; }
    .section-footer .container div:nth-child(1) {
      display: none; }
    .section-footer .container .copyright {
      text-align: center; }
  .usluge-a {
    background-size: 80% auto;
    top: 5rem; }
    .usluge-a .container .usluge-opis {
      width: 80%;
      padding: 4rem 0 3rem 0; }
  .usluge-b {
    margin-top: 4rem;
    padding: 4rem 0; }
    .usluge-b .container {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 4rem; }
      .usluge-b .container .usluga-box:nth-child(2n - 1) {
        top: 0rem;
        position: relative; }
      .usluge-b .container .usluga-box:nth-child(2n) {
        top: 7rem;
        position: relative; }
  .reference h3 {
    font-size: 0.95rem; }
  .about-a .container {
    grid-template-columns: 1fr;
    grid-gap: 0rem;
    overflow: visible; }
    .about-a .container .about-slika {
      order: 2;
      margin-top: -10rem; }
    .about-a .container .about-opis {
      padding: 2rem 0 2rem 0rem; }
    .about-a .container img {
      top: 10rem; }
  .about-b .blok {
    padding: 10rem 0 2rem 0;
    width: 100%; }
  .kontakt-a .container {
    grid-template-columns: 1fr;
    row-gap: 0; }
    .kontakt-a .container .kontakt-form {
      padding: 1.5rem 0 0 0; }
  .kontakt .kontakt-b {
    grid-template-columns: 1fr;
    justify-items: center; }
    .kontakt .kontakt-b .map {
      width: 100%;
      height: 400px; }
    .kontakt .kontakt-b .info-box {
      width: 70%;
      margin-top: -80px; }
  .parallax-header-trava {
    width: 50%; }
  .usluge .parallax-trava {
    width: 50%;
    margin-top: 200px; } }

@media screen and (max-width: 500px) {
  .pocetna h2,
  .usluge h2,
  .reference h2,
  .about h2,
  .kontakt h2 {
    font-size: 2rem; }
  .navbar .nav {
    display: none; }
  .navbar .menu-btn-container {
    display: flex; }
  .navbar .logo {
    font-size: 1.5rem; }
  .pocetna-b .container .img-squareH {
    margin-right: 0;
    width: 100%; }
  .pocetna-b .container .slikaR {
    margin-top: -30%; }
  .pocetna-a .decor-circle {
    display: none;
    left: 10%;
    top: 15%;
    width: 20rem;
    height: 20rem; }
  .usluge-a .container .usluge-opis {
    width: 100%;
    padding: 4rem 0 2rem 0; }
  .usluge-a {
    top: 2rem;
    background-size: 100% auto; }
    .usluge-a .container .usluge-opis {
      width: 100%; }
  .usluge-b {
    margin-top: 1rem;
    padding: 2rem 0; }
    .usluge-b .container {
      grid-template-columns: 1fr;
      row-gap: 3rem; }
      .usluge-b .container .usluga-box:nth-child(n) {
        top: 0;
        position: relative; }
  .kontakt .kontakt-b .info-box {
    width: 90%; }
    .kontakt .kontakt-b .info-box div {
      padding-left: 0rem;
      text-align: center; }
  .parallax-header-trava {
    margin-top: 400px; }
  .usluge .parallax-trava {
    margin-top: 300px;
    width: 60%; } }
